import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'
import Contact from '../components/Contact'
import Packages from '../components/Packages'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

   {
    path: '/about',
    name: 'about',
    component: About
  },


   {
    path: '/contact',
    name: 'contact',
    component: Contact
  },

   {
    path: '/packages',
    name: 'packages',
    component: Packages
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
