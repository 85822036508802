<template>

<Header />


<section style="background-image: url('/assets/images/ocean-travel-modern-nobody-infinity (1).jpg'); background-size: cover; background-position: center; padding-top: 200px; padding-bottom: 60px; background-color: rgba(0, 0, 0, 0.7); background-blend-mode: darken; ">

	<div class="container">

		<div class="row">

			<div class="col-md-12" style="text-align: center;">
				<h1 class="color-3 animate__animated animate__zoomIn "><b>Our Packages</b></h1>

				<h6 class="color-2 animate__animated animate__zoomIn "><b>Maji Benders</b></h6>
				
			</div>
			
		</div>
		


	</div>


</section>





<section style="padding-bottom: 100px; padding-top: 100px;">
	
	<div style="text-align:center; padding-bottom: 30px;">
		<h1>Our Packages</h1>
	</div>


	<div class="container shadow" style="background-color: #f5f5f5;">

		<div class="row">

			<div class="col-md-6" style="background-image:url('/assets/images/side-view-smiley-girl-pool (1).jpg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3>6 Days Beginner Learn to swim and stay package </h3>
			<p>
			Diani is a popular destination known for its stunning coastline, warm weather, and beautiful coral reefs. We are excited to announce a stay-and-learn package for beach enthusiasts. This is a great opportunity to improve your confidence in the water and discover the wonders of the ocean. When you're not learning, Diani offers a range of activities including exploring amazing restaurants, shopping, hiking, enjoying sunset beach bonfires, visiting Shimba Hills game parks, and experiencing the African pool.
			</p>

			<h4 class="color-2">Inclusions:</h4>

<ol class="list-group list-group-numbered">
  <li class="list-group-item">Accommodation in a studio apartment 400m from the beach</li>
  <li class="list-group-item"> Confined water swimming classes with a Certified Instructor at your convenient hours</li>
  <li class="list-group-item">Confined water snorkelling lessons</li>
  <li class="list-group-item">Confined water snorkelling lessons</li>
  <li class="list-group-item">Glass bottom boat snorkelling trip in the Indian Ocean</li>
</ol>

			<div style="padding-top:30px;">
				<a href="https://wa.me/message/HI7PTRCHNJ4XE1" target="_blank" class="btn btn-1">Book A Spot</a>
			</div>
				
			</div>

			
			
		</div>



		
	</div>







	<div class="container shadow" style="background-color: #f5f5f5; margin-top: 50px;">

		<div class="row">

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3> Book swimming classes now! </h3>
			<p>
			We are thrilled to have you join our swimming classes. Whether you're an individual, a couple, or a child looking to gain confidence in the water, this is a wonderful opportunity to explore various water sports and enjoy ocean swimming with confidence. It's an exhilarating sport!"
			</p>


			<div style="padding-top:30px;">
			<a href="https://wa.me/message/HI7PTRCHNJ4XE1" target="_blank" class="btn btn-2">Book A Spot</a>
			</div>
				
			</div>


					<div class="col-md-6" style="background-image:url('/assets/images/friends-having-party-celebration-pool (1).jpg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			
			
		</div>



		
	</div>








		<div class="container shadow" style="background-color: #f5f5f5; margin-top: 50px;">

		<div class="row">

			<div class="col-md-6" style="background-image:url('/assets/images/ocean-travel-modern-nobody-infinity (1).jpg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3> Explore Diani water sports Packages </h3>
			<p>
			If you're a swimmer looking for the right package, here are some amazing water sports options for you, ranging from relaxed to adrenaline-pumping activities:
			</p>


			<div class="row">

				<div class="col-md-6">

					<ol class="list-group list-group-numbered">
  <li class="list-group-item">Deep sea fishing</li>
  <li class="list-group-item">  Jet ski</li>
  <li class="list-group-item">Banana boat ride</li>
  <li class="list-group-item">Confined water snorkelling lessons</li>
  <li class="list-group-item">Towable inflatables</li>
  <li class="list-group-item">Windsurfing</li>
   <li class="list-group-item">Kite surfing</li>
</ol>
					
				</div>


				<div class="col-md-6">

					<ol class="list-group list-group-numbered">
  <li class="list-group-item">   Kayak rental</li>
  <li class="list-group-item">Body board</li>
  <li class="list-group-item"> Stand-up paddle board rentals</li>
  <li class="list-group-item">Scuba diving</li>
  <li class="list-group-item">Snorkelling trips</li>
  <li class="list-group-item">Boat cruise</li>
</ol>
					
				</div>
				
			</div>



			<div style="padding-top:30px;">
				<a href="https://wa.me/message/HI7PTRCHNJ4XE1" target="_blank" class="btn btn-1">Book A Spot</a>
			</div>
				
			</div>

			
			
		</div>



		
	</div>











	<div class="container shadow" style="background-color: #f5f5f5; margin-top: 50px;">

		<div class="row">

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3> Book Stay only </h3>
			<p>
			Experience the charm of Diani Beach, Kenya, with a stay at Maji Bender Apartments. These modern studio apartments are conveniently situated just 400 meters from the beach, offering you a comfortable and relaxing retreat. Perfect for couples or solo travellers seeking a cosy stay. Prepare your meals in our fully equipped kitchens, allowing for self-catering and convenience.

			</p>


			<div style="padding-top:30px;">
				<a href="https://wa.me/message/HI7PTRCHNJ4XE1" target="_blank" class="btn btn-2">Book A Spot</a>
			</div>
				
			</div>


					<div class="col-md-6" style="background-image:url('/assets/images/love-packed-vacation-joyful-prep_882186-7325.jpeg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			
			
		</div>



		
	</div>







		<div class="container shadow" style="background-color: #f5f5f5; margin-top: 50px;">

		<div class="row">

			<div class="col-md-6" style="background-image:url('/assets/images/modern-families-retro-style (1).jpg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3> Travel planning and consultation </h3>
			<p>
			It's not always easy to plan a trip. Some of us love travelling but are clueless about what to do. We are here to assist you every step of the way:

			</p>


			<div class="row">

				<div class="col-md-12">

					<ol class="list-group list-group-numbered">
  <li class="list-group-item"> Hotel reservations</li>
  <li class="list-group-item">  Transfers</li>
  <li class="list-group-item"> Airbnb reservations</li>
  <li class="list-group-item">Activity planning</li>
  <li class="list-group-item">Parties organization</li>
  <li class="list-group-item">Know where the party is at!</li>
</ol>
					
				</div>

				
			</div>



			<div style="padding-top:30px;">
				<a href="https://wa.me/message/HI7PTRCHNJ4XE1" target="_blank" class="btn btn-1">Book A Spot</a>
			</div>
				
			</div>

			
			
		</div>



		
	</div>






</section>







<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	
	export default{
		name : 'header',
		components : {Header,Footer},
		created(){
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

</script>