<template>

<Header />


<section style="background-image: url('/assets/images/ocean-travel-modern-nobody-infinity (1).jpg'); background-size: cover; background-position: center; padding-top: 200px; padding-bottom: 60px; background-color: rgba(0, 0, 0, 0.7); background-blend-mode: darken; ">

	<div class="container">

		<div class="row">

			<div class="col-md-12" style="text-align: center;">
				<h1 class="color-3 animate__animated animate__zoomIn "><b>About Us</b></h1>

				<h6 class="color-2 animate__animated animate__zoomIn "><b>Maji Benders</b></h6>
				
			</div>
			
		</div>
		


	</div>


</section>




<section style="padding-top:100px; padding-bottom: 100px;">
	
	<div class="container shadow" style="background-color: #f5f5f5;">

		<div class="row">

			<div class="col-md-6" style="background-image:url('/assets/images/ocean-travel-modern-nobody-infinity (1).jpg'); background-size: cover; background-position: center; min-height: 300px; ">
				
			</div>

			<div class="col-md-6" style="padding-top: 50px; padding-bottom: 50px; ">

				<h3 class="color-2">About Us</h3>
			<h1>Who We Are</h1>

			<p>
				Dive into the world of Maji Benders! Let's explore the joy of being in the water. Swimming is a timeless sport that anyone can master. Our goal is to create more Ocean awareness as we help individuals of all ages and abilities unleash their full potential in the water. With our year-round lessons and tailored programs, Maji Benders has something special for everyone. Join Maji Benders in building your confidence in the water
			</p>

			<div>
				<router-link to="/contact" class="btn btn-1">Contact Us</router-link>
			</div>
				
			</div>

			
			
		</div>
		
	</div>

</section>





<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	
	export default{
		name : 'header',
		components : {Header,Footer},
		created(){
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

</script>