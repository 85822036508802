<template>


<div style="position: fixed; z-index: 900; top: 0; right: 0; width: 100%; ">
	
	<div class="container">

			<nav class="navbar navbar-expand-lg navbar-light bg-light header-design">
  <div class="container-fluid">
    <router-link to="/" class="navbar-brand">
    <img src="/assets/images/logo.png" class="logo">	
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>


        <li class="nav-item">
          <router-link to="/about" class="nav-link">About Us</router-link>
        </li>


          <li class="nav-item">
          <router-link to="/packages" class="nav-link">Our Packages</router-link>
        </li>
        



         <li class="nav-item">
          <router-link to="/contact" class="nav-link">Contact Us</router-link>
        </li>
       



      </ul>
      <div class="d-flex">
        <router-link to="/contact" class="btn btn-1">Book Now</router-link>
      </div>
    </div>
  </div>
</nav>
		
	</div>


</div>


</template>