<template>

<Header />


<section style="background-image: url('/assets/images/ocean-travel-modern-nobody-infinity (1).jpg'); background-size: cover; background-position: center; padding-top: 200px; padding-bottom: 60px; background-color: rgba(0, 0, 0, 0.7); background-blend-mode: darken; ">

	<div class="container">

		<div class="row">

			<div class="col-md-12" style="text-align: center;">
				<h1 class="color-3 animate__animated animate__zoomIn "><b>Contact Us</b></h1>

				<h6 class="color-2 animate__animated animate__zoomIn "><b>Maji Benders</b></h6>
				
			</div>
			
		</div>
		


	</div>


</section>




<section style="background-color:#f5f5f5; padding-top: 100px; padding-bottom: 100px;">
	

	<div class="container">

		<div class="row">

			<div class="col-md-4 mt-3" style="text-align:center; border-right: solid 1px lightgrey; ">

				<h1> <i class="bi bi-telephone-fill color-2"></i> </h1>
				<h6>0740683859</h6>
				
			</div>


			<div class="col-md-4 mt-3" style="text-align:center; border-right: solid 1px lightgrey;">

				<h1> <i class="bi bi-envelope color-2"></i> </h1>
				<h6>majibenders@gmail.com</h6>
				
			</div>


			<div class="col-md-4 mt-3" style="text-align:center;">

				<h1> <i class="bi bi-geo-alt color-2"></i> </h1>
				<h6>Diani Beach,Mombasa</h6>
				
			</div>
			
		</div>
		
	</div>


</section>





<Footer />


</template>

<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	
	export default{
		name : 'header',
		components : {Header,Footer},
		created(){
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}

</script>