<template>
	

	<div style="background-color: #1a1a1a; text-align: center; padding-top: 40px; padding-bottom: 10px;">
		
		<div>
			<h6 class="color-1">Maji Benders © 2024. All Rights Reserved</h6>
		</div>

	</div>


</template>